<template>
  <b-container class="container-content">
    <div class="d-flex justify-content-center">
      <div class="page-header-text top10" style="top: 10px">
        <h4 class="mb-3">{{ $t('warning.reset_password') }}</h4>
      </div>
    </div>
    <div class="mt-5" style="padding-left: 20%; padding-right: 20%">
      <b-form @submit="confirmNewPassword">
        <b-form-group cols="6">
          <p class="text-xl">{{ $t('reset_password.new_password') }}</p>
          <b-form-input
            type="password"
            v-model="newpassword"
            @change=";(isRetry = true), (generatErr = false)"
            :placeholder="$t('reset_password.new_password')"
            size="default"
            required
          ></b-form-input>
          <p class="text-xl mt-4">
            {{ $t('reset_password.confirm_new_password') }}
          </p>
          <b-form-input
            type="password"
            v-model="confirmpassword"
            @change=";(isRetry = true), (generatErr = false)"
            :placeholder="$t('reset_password.confirm_new_password')"
            size="default"
          ></b-form-input>
        </b-form-group>
        <p class="text-danger my-3" v-if="isConfirmPassword && isConfirmPassword !== 'yes'">
          <b-icon icon="exclamation-circle-fill" variant="danger"></b-icon>
          {{ $t('pages.reset_password.sorry_your_password_resetting_request') }}
        </p>
        <p v-if="generatErr" class="text-danger mt-3">
          <b-icon icon="exclamation-circle-fill" variant="danger"></b-icon>
          {{ $t('pages.reset_password.the_two passwords_entered_must_be_the_same') }}
        </p>
        <b-row class="justify-content-center mt-4">
          <b-button
            type="submit"
            variant="primary"
            class="btn-primary my-1 w380"
            style="background: grey; border-color: grey"
            v-if="!isRetry"
            disabled
            >{{ $t('warning.reset_password') }}</b-button
          >
          <b-button v-else type="submit" variant="primary" class="btn-primary my-1 w380" required>{{
            $t('warning.reset_password')
          }}</b-button>
        </b-row>
        <br />
        <!-- <b-button variant="outline-light" class="btn-comin my-1 w380">{{
          $t("button.go_back")
        }}</b-button> -->
      </b-form>
    </div>
  </b-container>
</template>
<script>
// import Vue from "vue";
// import { Component } from "vue-property-decorator";
import { createNamespacedHelpers } from 'vuex'

// const UserStore = createNamespacedHelpers("user");
const AuthStore = createNamespacedHelpers('auth')

export default {
  data() {
    return {
      newpassword: null,
      confirmpassword: null,
      oobCode: null,
      pass: false,
      generatErr: false,
      isRetry: true,
    }
  },
  mounted() {
    let j = window.location.search.substr(1)
    j = j.split('&')
    let b = j[1].split('=')
    let lang = j[3].split('=')
    console.log('mmmmm', j[0])
    if (j[0] && j[0] === 'mode=recoverEmail') {
      console.log('route')
      this.$router.push({ name: 'ContactUs' })
    } else {
      this.oobCode = b[1]
      console.log('j::', this.oobCode)
      this.confirmPassword({
        oobCode: this.oobCode,
      })
      if (lang[1] === 'th') {
        this.$i18n.locale = 'th'
      } else {
        this.$i18n.locale = 'en'
      }
      this.isRetry = true
    }
  },
  methods: {
    ...AuthStore.mapActions([
      'signOut',
      'signIn',
      'signUpEmail',
      'logInEmail',
      'resetPasswordbyEmail',
      'confirmPassword',
    ]),
    ...AuthStore.mapMutations(['ISCONFIRMPASSWORD']),

    confirmNewPassword(e) {
      this.isRetry = false
      e.preventDefault()
      this.ISCONFIRMPASSWORD('home')
      if (this.newpassword === this.confirmpassword && this.newpassword && this.confirmpassword) {
        if (this.newpassword.length === this.confirmpassword.length) {
          this.generatErr = false
          this.confirmPassword({
            oobCode: this.oobCode,
            newPassword: this.confirmpassword,
          })
          this.pass = true
        }
      } else {
        this.generatErr = true
        this.pass = false
      }
      // if (!this.generatErr) {
      //   this.confirmPassword({
      //     oobCode: this.oobCode,
      //     newPassword: this.newpassword,
      //   });
      //   this.pass = true;
      // } else {
      //   this.pass = false;
      // }

      // if (this.newpassword === this.confirmpassword) {
      //   this.confirmPassword({
      //     oobCode: this.oobCode,
      //     newPassword: this.newpassword,
      //   });

      //   this.pass = true;
      // } else {
      //   this.pass = false;
      // }

      // this.newpassword ==
    },
  },
  computed: {
    ...AuthStore.mapState(['isConfirmPassword', 'emailReset']),
  },
  watch: {
    async isConfirmPassword(newValue, oldValue) {
      console.log('newValue', newValue)
      if (newValue === 'yes' && this.pass) {
        await this.logInEmail({
          email: this.emailReset,
          password: this.newpassword,
        })
        this.$router.push({ name: 'Home' })
        // this.isRetry = false;
      } else {
        this.isRetry = false
      }
    },
  },
}
</script>
<style lang="css" scoped>
.card-container {
  min-height: auto;
}
.w380 {
  width: 380px;
}
@media (max-width: 767.98px) {
  .w380 {
    width: 300px !important;
    font-size: 1rem !important;
  }
}
@media (max-width: 375px) {
  .w380 {
    width: 250px !important;
    font-size: 0.95rem !important;
  }
}
@media (max-width: 340px) {
  .w380 {
    width: 200px !important;
    font-size: 0.95rem !important;
  }
}
</style>
